import React from 'react';

import TopSection from './TopSection';
import MidleSection from './MidleSection';
import BottomSection from './BottomSection';
import FooterSection from './FooterSection';

function LandingPage() {
  return (
    <>
      <TopSection />
      <MidleSection />
      <BottomSection />
      <FooterSection />
    </>
  );
}

export default LandingPage;
